// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
// import Projects from "./components/Projects";
// import CurrencyRupeeSharpIcon from '@mui/icons-material/CurrencyRupeeSharp';

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Lottie from "lottie-react";
import adding from "../../illustrations/adding.json";
import Tooltip from "@mui/material/Tooltip";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [isHovered, setIsHovered] = useState(false);
  const [isRupeeHovered, setIsRupeeHovered] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const handleMouseEnterRupee = () => {
    setIsRupeeHovered(true);
  };

  const handleMouseLeaveRupee = () => {
    setIsRupeeHovered(false);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Projects
                paymentModal={paymentModal}
                setPaymentModal={setPaymentModal}
              />
            </Grid>
            <Tooltip
              title={"Pay pending payment"}
              placement="top"
            >
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="3.25rem"
                height="3.25rem"
                bgColor="success"
                shadow="sm"
                borderRadius="50%"
                position="fixed"
                right="9.5rem"
                zIndex={99}
                color="dark"
                className="inrSymbol"
                sx={{
                  cursor: "pointer",
                  transition: "transform 0.3s",
                  transform: isRupeeHovered
                    ? "translateY(-10px)"
                    : "translateY(0)",
                }}
                onMouseEnter={handleMouseEnterRupee}
                onMouseLeave={handleMouseLeaveRupee}
                onClick={()=>setPaymentModal(true)}
              >
                <span style={{ color: "white" }}>₹</span>
              </MDBox>
              </Tooltip>
            <Tooltip
              title={"Add a new server"}
              placement="top"
            >
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="3.25rem"
              height="3.25rem"
              bgColor="black"
              shadow="sm"
              borderRadius="50%"
              position="fixed"
              right="5rem"
              zIndex={99}
              color="dark"
              className="addServer"
              sx={{
                cursor: "pointer",
                transition: "transform 0.3s",
                transform: isHovered ? "translateY(-10px)" : "translateY(0)",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <NavLink to={"/deploy"}>
                <Lottie
                  style={{ transform: "scale(2)" }}
                  animationData={adding}
                />
              </NavLink>
            </MDBox>
            </Tooltip>
          </Grid>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
