import http from "./httpService";
import jwtDecode from "jwt-decode";
const apiEndpoint = `${process.env.REACT_APP_API_URL}/auth`;

// http.setJwt(getJwt());   jjust trying by commenting out the axios common headers for ip address api because its not allowing it.now we have to send the headers manually

export async function login(user) {
  console.log("user in auth login", user);
  const { data: jwt } = await http.post(apiEndpoint, {
    //unfortunately we are modifying this code because now its not only sending jwt but it can also send a string so we cannot directly assign it to localstorage.setitem
    email: user.email,
    password: user.password,
    currentIp: user.currentIp,
  });
  console.log("what we are getting on jwt ", jwt);
  if (jwt === "OTP Sent To Email. Please Verify it.") {
    return jwt;
  } else {
    localStorage.setItem("token", jwt);
  }
}
export async function logout() {
  localStorage.removeItem("token");
}
export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("token");
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}
export function loginWithJwt(jwt) {
  localStorage.setItem("token", jwt);
}
export function getJwt() {
  return localStorage.getItem("token");
}
export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
