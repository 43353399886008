// import * as React from "react";
// import { useState, useEffect } from "react";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// // import Link from "@mui/material/Link";
// import { Link } from "react-router-dom";
// import swal from "sweetalert";

// import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { register } from "../../services/userService";
// import authService from "../../services/authService";
// import sarvvidIcon from "../../imgs/Sarvvid-logo.png";
// import sarvvidBackground from "../../imgs/Sarvvid-BG.png";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     ></Typography>
//   );
// }

// // TODO remove, this demo shouldn't need to reset the theme.

// const defaultTheme = createTheme();

// export default function SignUp() {
//   const [screenHeight, setScreenHeight] = useState(window.innerHeight);

//   useEffect(() => {
//     // Function to update the screenWidth state when the window is resized
//     const handleResize = () => {
//       setScreenHeight(window.innerHeight);
//     };

//     // Add event listener for window resize
//     window.addEventListener("resize", handleResize);

//     // Cleanup the event listener when the component unmounts
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const [showPassword, setShowPassword] = React.useState(false);
//   const handleTogglePasswordVisibility = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };

//   const handleHover = (e) => {
//     e.currentTarget.style.transform = "translateY(-5px)";
//   };

//   const handleHoverExit = (e) => {
//     e.currentTarget.style.transform = "translateY(0)";
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     console.log({
//       firstName: data.get("firstName"),
//       lastName: data.get("lastName"),
//       email: data.get("email"),
//       password: data.get("password"),
//     });
// try {
//   const response = await register({
//     firstName: data.get("firstName"),
//     lastName: data.get("lastName"),
//     email: data.get("email").toLowerCase(),
//     password: data.get("password"),
//   });
//   authService.loginWithJwt(response.headers["x-auth-token"]);
//   swal(`Verification Link sent to Email`, {
//     icon: "success",
//   }).then((result) => {
//     console.log("user clicked ok");
//     window.location = "/";
//   });
// } catch (err) {
//   console.log("there's some error hitting the api");
//   if (err.message === "Request failed with status code 400") {
//     swal(`User already registered.`, {
//       icon: "info",
//     });
//   } else {
//     swal(`Server error. Try again later.`, {
//       icon: "error",
//     });
//   }
// }
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       {/* <Box sx={{ bgcolor: "black", padding: "2rem", minHeight: "95vh" }}> */}
//       <Box
//         sx={{
//           //   bgcolor: "black",
//           bgcolor: "black",
//           // padding: "2rem",
//           minHeight: "95vh",
//           backgroundImage: `url(${sarvvidBackground})`, // Replace "path/to/your/image.jpg" with the actual path to your background image.
//           backgroundRepeat: "no-repeat",
//           backgroundSize: "cover",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//         }}
//       >
//         <Container
//           component="main"
//           maxWidth="xs"
//           sx={{
//             minHeight: screenHeight < 900 ? "130vh" : undefined,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <CssBaseline />
//           <Box sx={{ textAlign: "center" }}>
//             <img src={sarvvidIcon} style={{ width: "14rem" }} />
//           </Box>
//           <Box
//             sx={{
//               marginTop: "5%",
//               // minHeight: "150vh",
//               display: "flex",
//               flexDirection: "column",
//               minHeight: "455px",
//               alignItems: "center",
//               bgcolor: "white",
//               padding: "2rem",
//               borderRadius: "10px",
//               transition: "transform 0.2s ease",
//               // boxShadow: "rgba(255, 255, 255, 0.8) 0px 0px 20px",
//               boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 7px",
//               width: "22.5rem",
//             }}
//             onMouseEnter={handleHover}
//             onMouseLeave={handleHoverExit}
//           >
//             <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
//               <LockOutlinedIcon />
//             </Avatar>
//             <Typography component="h1" variant="h5">
//               Sign up
//             </Typography>
//             <Box
//               component="form"
//               noValidate
//               onSubmit={handleSubmit}
//               sx={{ mt: 3 }}
//             >
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     autoComplete="given-name"
//                     name="firstName"
//                     required
//                     fullWidth
//                     id="firstName"
//                     label="First Name"
//                     autoFocus
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     required
//                     fullWidth
//                     id="lastName"
//                     label="Last Name"
//                     name="lastName"
//                     autoComplete="family-name"
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     fullWidth
//                     id="email"
//                     label="Email Address"
//                     name="email"
//                     autoComplete="email"
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   {/* <TextField
//                     required
//                     fullWidth
//                     name="password"
//                     label="Password"
//                     type="password"
//                     id="password"
//                     autoComplete="new-password"
//                   /> */}

//                   <TextField
//                     margin="normal"
//                     required
//                     fullWidth
//                     name="password"
//                     label="Password"
//                     type={showPassword ? "text" : "password"}
//                     id="password"
//                     autoComplete="current-password"
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <IconButton
//                             edge="end"
//                             onClick={handleTogglePasswordVisibility}
//                             onMouseDown={(e) => e.preventDefault()}
//                           >
//                             {showPassword ? <VisibilityOff /> : <Visibility />}
//                           </IconButton>
//                         </InputAdornment>
//                       ),
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   {/* <FormControlLabel
//                     control={
//                       <Checkbox value="allowExtraEmails" color="primary" />
//                     }
//                     label="I want to receive inspiration, marketing promotions and updates via email."
//                   /> */}
//                 </Grid>
//               </Grid>
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2 }}
//               >
//                 Sign Up
//               </Button>
//               <Grid container justifyContent="flex-end">
//                 <Grid item>
//                   <Link to="/signin" variant="body2">
//                     Already have an account? Sign in
//                   </Link>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Box>
//           <Copyright sx={{ mt: 5 }} />
//         </Container>
//       </Box>
//     </ThemeProvider>
//   );
// }

import * as React from "react";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { register as registerUser } from "../../services/userService";
import authService from "../../services/authService";
import sarvvidIcon from "../../imgs/Sarvvid-logo.png";
import sarvvidBackground from "../../imgs/Sarvvid-BG.png";
import axios from "axios";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(/@[^.]*\./, "Invalid email address"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  termsAndConditions: yup
    .bool()
    .oneOf([true], "You must accept the Terms and Conditions"),
});
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    ></Typography>
  );
}
const defaultTheme = createTheme();
export default function SignUp() {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Function to update the screenWidth state when the window is resized
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users`,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email.toLowerCase(),
          password: data.password,
        },
        { headers: { "x-auth-token": authService.getJwt() } }
      );
      console.log("response from signup", response)
      await swal(`Verification Link sent to Email`, {
        icon: "success",
      }).then((result) => {
        console.log("user clicked ok");
        window.location = "/";
      });
    } catch (err) {
      console.log("there's some error hitting the api", err);
      if (err.message === "Request failed with status code 400") {
        swal(`User already registered.`, {
          icon: "info",
        });
      } else {
        swal(`Server error. Try again later.`, {
          icon: "error",
        });
      }
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleHover = (e) => {
    e.currentTarget.style.transform = "translateY(-5px)";
  };

  const handleHoverExit = (e) => {
    e.currentTarget.style.transform = "translateY(0)";
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          //   bgcolor: "black",
          bgcolor: "black",
          // padding: "2rem",
          minHeight: "100vh",
          backgroundImage: `url(${sarvvidBackground})`, // Replace "path/to/your/image.jpg" with the actual path to your background image.
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            minHeight: screenHeight < 900 ? "130vh" : undefined,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CssBaseline />
          <Box sx={{ textAlign: "center" }}>
            <img src={sarvvidIcon} style={{ width: "14rem" }} />
          </Box>
          <Box
            sx={{
              marginTop: "5%",
              // minHeight: "150vh",
              display: "flex",
              flexDirection: "column",
              minHeight: "455px",
              alignItems: "center",
              bgcolor: "white",
              padding: "2rem",
              borderRadius: "10px",
              transition: "transform 0.2s ease",
              // boxShadow: "rgba(255, 255, 255, 0.8) 0px 0px 20px",
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 7px",
              width: "22.5rem",
            }}
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverExit}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("firstName")}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName?.message}
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("lastName")}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message}
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("email")}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("password")}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleTogglePasswordVisibility}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="termsAndConditions"
                    control={control}
                    defaultValue={false}
                    error={Boolean(errors.termsAndConditions)}
                    helperText={errors.termsAndConditions?.message}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            color="primary"
                          />
                        }
                        label={
                          <span>
                            I agree to the{" "}
                            {/* <Link to="/privacypolicy" style={{ color: "blue" }}> */}
                            Privacy Policy{" "}
                            {/* </Link>{" "} */}
                            and{" "}
                            {/* <Link
                              to="/termsandconditions"
                              style={{ color: "blue" }}
                            > */}
                            Terms and Conditions
                            {/* </Link> */}
                          </span>
                        }
                      />
                    )}
                  />
                  <p
                    style={{ color: "red", fontSize: "14px", marginTop: "5px" }}
                  >
                    {errors.termsAndConditions?.message}
                  </p>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/signin" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
