import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// import deleteIcon from "../../illustrations/delete.json";
// import signupIcon from "../../illustrations/signin.json";
import serverStack from "../../../../illustrations/serverStack.json";
import adding from "../../../../illustrations/adding.json";
import signupIcon from "../../../../illustrations/signin.json";
import deleteIcon from "../../../../illustrations/delete.json";
import logoXD from "assets/images/small-logos/logo-xd.svg";
import { FaInfo, FaTrashAlt } from "react-icons/fa";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "./DataTable/index";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Data
import Data from "layouts/dashboard/components/Projects/data";
import axios from "axios";
import authService from "services/authService";
import swal from "sweetalert";
import Details from "layouts/details";

function Projects({ paymentModal, setPaymentModal }) {
  const navigate = useNavigate();

  const { columns } = Data();
  const [menu, setMenu] = useState(null);
  const [userServers, setUserServers] = useState([]);
  const [modal, setModal] = useState(false);
  const [serverDetails, setServerDetails] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [password, setPassword] = useState(null);
  const [pendingPayments, setPendingPament] = useState([]);
  const [showPassword, setShowPassword] = useState("{password}");
  const [copySuccess, setCopySuccess] = useState("");
  const user = authService.getCurrentUser();


  const closeModal = () => {
    console.log("Closing modal");
    setPaymentModal(false);
    setModal(false);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(password);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy text");
    }
  };

  useEffect(() => {
    const fetchCurrentUserDecryptedPassword = async () => {
      console.log("we are in the fetch decryption password block");
      const rowData = serverDetails ? serverDetails : null;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/fetchDecryption`,
          { rowData, user }
        );
        console.log("the response from fetched decryption", response);

        console.log(
          "checking the password decrypted before pushing it to object",
          response.data
        );
        setPassword(response.data);
      } catch (error) {
        console.error("Error fetching decrypted password:", error);
      }
    };

    fetchCurrentUserDecryptedPassword();
  }, [serverDetails]);
  useEffect(() => {
    const fetchPaymentInfo = async () => {
      console.log("we are in the fetch payment info block");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fetchPayments`,
          { headers: { "x-auth-token": authService.getJwt() } }
        );
        console.log("the response from fetch payment info", response);
        console.log(response.data);
        setPendingPament(response.data);
        if (response.data?.length > 0) {
          setPaymentModal(true);
        }
      } catch (error) {
        console.error("Error fetching paymentinfo:", error);
      }
    };

    fetchPaymentInfo();
  }, []);

  const toggle = (data) => {
    setModal(!modal);
    const dateCreatedString = data.date_created;
    const dateCreated = new Date(dateCreatedString);
    const currentDate = new Date();
    const timeDifferenceMs = currentDate.getTime() - dateCreated.getTime();
    const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60); // Convert milliseconds to hours
    let perHourCharge;
    if (data.plan === "vhp-1c-1gb-amd") {
      perHourCharge = 0.674;
    } else if (data.plan === "vhp-1c-2gb-amd") {
      perHourCharge = 3.205;
    }
    const currentCharges = perHourCharge * timeDifferenceHours;
    const roundedCharges = currentCharges.toFixed(1);
    const updatedData = {
      ...data,
      roundedCharges: roundedCharges,
    };

    setServerDetails(updatedData);
  };
  const Servername = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );
  // console.log("servers that user purchased", userServers);

  const rows = userServers.map((data, index) => {
    const dateCreatedString = data.date_created;
    const dateCreated = new Date(dateCreatedString);
    const currentDate = new Date();
    const timeDifferenceMs = currentDate.getTime() - dateCreated.getTime();
    const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60); 
    let perHourCharge;
    if (data.plan === "vhp-1c-1gb-amd") {
      perHourCharge = 0.674;
    } else if (data.plan === "vhp-1c-2gb-amd") {
      perHourCharge = 3.205;
    }
    const currentCharges = perHourCharge * timeDifferenceHours;
    const roundedCharges = currentCharges.toFixed(1);
    return {
      server: (
        <Servername
          image={logoXD}
          name={`${data.hostname} ${data.ram}MB ${
            data.plan.includes("amd") && data.plan.includes("vhp")
              ? "AMD HIGH PERFORMANCE"
              : ""
          }-${data.main_ip}`}
        />
      ),
      os: (
        <MDBox display="flex" py={1} alignItems="center" gap="10px">
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {data.os}
          </MDTypography>
        </MDBox>
      ),
      location: (
        <MDBox display="flex" py={1} alignItems="center" gap="10px">
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {`${
              data.region === "del"
                ? "Delhi"
                : data.region === "blr"
                ? "Bangalore"
                : data.region === "bom"
                ? "Mumbai"
                : "Singapore"
            }`}
          </MDTypography>
        </MDBox>
      ),
      charges: (
        <MDBox display="flex" py={1} alignItems="center" gap="10px">
          <MDTypography variant="caption" color="text" fontWeight="medium">
            ₹ {roundedCharges}
          </MDTypography>
        </MDBox>
      ),
      status: (
        <MDBox display="flex" py={1} alignItems="center" gap="10px">
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {data.status}
          </MDTypography>
        </MDBox>
      ),
      destroy: (
        <MDBox display="flex" py={1} alignItems="center" gap="10px">
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {
              <button
                className="btn btn-danger"
                disabled={data.status==="pending"}
                onClick={() => handleDelete(data)}
              >
                <FaTrashAlt />
              </button>
            }
          </MDTypography>
        </MDBox>
      ),
      serverinfo: (
        <MDBox display="flex" py={1} alignItems="center" gap="10px">
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {
              <button className="btn btn-info" onClick={() => toggle(data)}>
                <FaInfo />
              </button>
            }
          </MDTypography>
        </MDBox>
      ),
    };
  });
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    // Function to run when the component mounts (page loads)
    console.log("Page loaded");
    const fetchData = async () => {
      try {
        const user = authService.getCurrentUser();
        console.log("user", user);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/servers`,
          { headers: { "x-auth-token": authService.getJwt() } }
        );
        console.log("Response from servers list", response.data);
        const filteredUserServers = response.data.filter((e) =>
          e.hostname.includes(user.firstName.replace(/\s/g, ""))
        );
        setUserServers(filteredUserServers);
      } catch (error) {
        console.error("Error fetching server data:", error);
      }
    };

    fetchData(); // Call the async function immediately
    return () => {
      console.log("Page unloaded");
      // Additional clean-up logic (if needed)
    };
  }, []);
  const handlePayNow = async (paymentDetails) => {
    var options = {
      key: process.env.REACT_APP_RAZORPAY_ID,
      key_secret: process.env.REACT_APP_RAZORPAY_SECRET,
      // amount: parseInt(serverCost * 100),
      amount: parseInt(paymentDetails.paymentAmount * 100),
      // amount: parseInt(100),

      currency: "INR",
      name: `ServerID- ${paymentDetails.serverId}`,
      description: `server ID - ${paymentDetails.serverId}`,
      handler: async function (response) {
        console.log("amount in handler function after payment", options.amount);

        try {
          const response1 = await axios.post(
            `${process.env.REACT_APP_API_URL}/clickPayNow`,
            {
              serverId: paymentDetails.serverId,
              paymentAmount: options.amount,
              paymentRefNo: response.razorpay_payment_id,
            },
            { headers: { "x-auth-token": authService.getJwt() } }
          );
          console.log("response from clickpaynow", response1);
        } catch (err) {
          console.log("there is some error in making payment", err);
        }
        // alert(response.razorpay_payment_id);
        swal(
          `Payment against the server is made. PaymentId is - ${response.razorpay_payment_id}`,
          {
            icon: "success",
          }
        ).then(() => {
          navigate("/instances");
        });
      },
      // prefill: {
      //   name: "Velmurugan",
      //   email: "mvel1620r@gmail.com",
      //   contact: "7904425033",
      // },
      // notes: {
      //   address: "Razorpay Corporate office",
      // },
      theme: {
        color: "#3399cc",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };
  const handleDelete = async (server) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Server!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/deleteServer`,
          { serverid: server.id },
          { headers: { "x-auth-token": authService.getJwt() } }
        );
        console.log("response from delete server", response);

        await swal(`Server has been deleted! ID: ${server.id}`, {
          icon: "success",
        });
        window.location = "/";
      } else {
        swal("Server is not deleted!");
      }
    });
    // console.log("handle delete clicked");
    // console.log("server", server);
  };

  // console.log("state", userServers);

  const handleGoBack = () => {
    console.log("we triggered the selected row data to null so it can goback");
    setSelectedRowData(null);
  };

  const handleDetailsClick = (rowData) => {
    console.log("we triggered the details click");
    setSelectedRowData(rowData);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  const listItemStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
    color: "#666",
  };

  return (
    <Card>
      <div
        className="container-fluid "
        // style={{ minHeight: "92vh" }}
      >
        <div
          className="container-fluid pt-4 px-4"
          style={{ position: "relative" }}
        >
          <div
          // className={`${
          //   LightModeOn ? "bg-white" : "bg-secondary"
          // }  text-center rounded `}
          >
            {selectedRowData ? (
              <Details rowData={selectedRowData} handleBack={handleGoBack} />
            ) : (
              <div className="table-responsive" style={{ marginBottom: "0px" }}>
                {authService.getCurrentUser() ? (
                  userServers.length !== 0 ? (
                    <MDBox pt={6} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox
                              mx={2}
                              mt={-3}
                              py={3}
                              px={2}
                              variant="gradient"
                              bgColor="info"
                              borderRadius="lg"
                              coloredShadow="info"
                            >
                              <MDTypography variant="h6" color="white">
                                Server Purchases
                              </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                              <DataTable
                                table={{ columns, rows }}
                                isSorted={false}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                noEndBorder
                                clickableRows
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                      <Modal isOpen={modal} toggle={closeModal}>
                        <ModalHeader toggle={closeModal}>
                          Server Details
                        </ModalHeader>
                        <ModalBody>
                          <ul>
                            <li>
                              <small>
                                Location: <b>{serverDetails.region}</b>
                              </small>
                            </li>
                            <li>
                              <small>
                                Ram: <b>{serverDetails.ram}</b>
                              </small>
                            </li>
                            <li>
                              <small>
                                Storage: <b>{serverDetails.disk}</b>
                              </small>
                            </li>
                            <li>
                              <small>
                                Label: <b>{serverDetails.label}</b>
                              </small>
                            </li>

                            <li>
                              <small>
                                HostName: <b>{serverDetails.hostname}</b>
                              </small>
                            </li>
                            <li>
                              <small>
                                OS: <b>{serverDetails.os}</b>
                              </small>
                            </li>
                            <li>
                              <small>
                                IP Address: <b>{serverDetails.main_ip}</b>
                              </small>
                            </li>
                            <li>
                              <small>
                                Current Charges:
                                <b>{serverDetails?.roundedCharges}</b>
                              </small>
                            </li>
                            <li>
                              <small>
                                Default Password:
                                <b>
                                  {showPassword
                                    ? password
                                    : password.replace(/./g, "*")}
                                </b>
                                <Tooltip
                                  title={showPassword ? "Hide" : "Show"}
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title={copySuccess ? copySuccess : "Copy"}
                                  placement="top"
                                >
                                  <IconButton onClick={copyToClipboard}>
                                    <FileCopyOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </small>
                            </li>
                          </ul>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={closeModal}>
                            Okay
                          </Button>
                        </ModalFooter>
                      </Modal>
                      {pendingPayments !== "No dues for upcoming cycle." && (
                        <Modal isOpen={paymentModal} toggle={closeModal} style={{}}>
                          <ModalHeader toggle={closeModal}>
                            Your Pending Payments
                          </ModalHeader>
                          {pendingPayments === "No dues for upcoming cycle."
                            ? null
                            : pendingPayments.map((payment, index) => {
                                return (
                                  <ModalBody
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      padding: "20px",
                                      backgroundColor: "#f4f4f4",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0px 4px 16px rgba(0, 0, 0, 0.25)",
                                      maxWidth: "600px",
                                      // margin: 'auto',
                                      margin: "20px",
                                    }}
                                  >
                                    <ul
                                      style={{
                                        width: "100%",
                                        listStyleType: "none",
                                        padding: "0",
                                      }}
                                    >
                                      <h3
                                        style={{
                                          textAlign: "center",
                                          color: "#333",
                                          borderBottom: "1px solid #ddd",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        Payment Pending
                                      </h3>
                                      <li style={listItemStyle}>
                                        Date Created{" "}
                                        <b>{payment.dateCreated}</b>
                                      </li>
                                      <li style={listItemStyle}>
                                        Amount:{" "}
                                        <b> ₹ {payment.paymentAmount}</b>
                                      </li>
                                      <li style={listItemStyle}>
                                        Status: <b>{payment.paymentStatus}</b>
                                      </li>
                                      <li style={listItemStyle}>
                                        Server Id: <b>{payment.serverId}</b>
                                      </li>
                                      <li style={listItemStyle}>
                                        Description:
                                        <b className="text-center">
                                          {payment.paymentStatus === "not paid"
                                            ? "to keep the demo services going kindly make payment."
                                            : payment.paymentStatus ===
                                              "cycle renewal payment to be done."
                                            ? "Server will be destroyed on monthly cycle date. Make sure to make payments on time."
                                            : null}
                                        </b>
                                      </li>
                                    </ul>
                                    <Button
                                      onClick={() => handlePayNow(payment)}
                                      color="primary"
                                      style={{
                                        marginTop: "20px",
                                        padding: "10px 20px",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        borderRadius: "5px",
                                        backgroundColor: "#3498db",
                                        color: "#fff",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Pay Now
                                    </Button>
                                  </ModalBody>
                                );
                              })}
                          <ModalFooter>
                            <Button color="primary" onClick={closeModal}>
                              Okay
                            </Button>
                          </ModalFooter>
                        </Modal>
                      )}
                    </MDBox>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Lottie
                        style={{
                          width: "15rem",
                          marginTop: "-5rem",
                          minHeight: "400px",
                        }}
                        animationData={serverStack}
                      />
                      <h3 style={{ marginTop: "1rem" }}>
                        Your Stack is Empty!
                      </h3>
                      <h6 style={{ marginTop: "1.5rem" }}>
                        Add servers to it now!
                      </h6>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h2>Get started by creating your account!</h2>
                    <Lottie
                      style={{ width: "20rem", marginTop: "5rem" }}
                      animationData={signupIcon}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Projects;
