/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import Lottie from "lottie-react";
import deleteIcon from "../../../../../illustrations/delete.json";
// Images
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect } from "react";
import authService from "services/authService";
import axios from "axios";
import { useState } from "react";
import DataTable from "../DataTable";
import swal from "sweetalert";

export default function Data() {

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  

  return {
   
    // columns: [
    //   { Header: "name", accessor: "companies", width: "45%", align: "left" },
    //   { Header: "assigned", accessor: "members", width: "10%", align: "left" },
    //   { Header: "amount", accessor: "budget", align: "center" },
    //   { Header: "completion", accessor: "completion", align: "center" },
    // ],
    columns: [
      { Header: "Server", accessor: "server", width: "45%", align: "left" },
      { Header: "OS", accessor: "os", width: "10%", align: "left" },
      { Header: "Location", accessor: "location", align: "center" },
      { Header: "Charges", accessor: "charges", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Destroy", accessor: "destroy", align: "center" },
      { Header: "Server-Information", accessor: "serverinfo", align: "center" },
    ],
  
   
  };
}
