import authService from "./authService";
import http from "./httpService";

const apiEndpoint = `${process.env.REACT_APP_API_URL}/users`;

export function register(user) {
  console.log(user);
  return http.post(
    apiEndpoint,
    {
      firstName: user.firstName,
      password: user.password,
      lastName: user.lastName,
      email: user.email,
    },
    { headers: { "x-auth-token": authService.getJwt() } }
  );
}
