/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function Data() {
  const Author = ({ image, name, email}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Clients", accessor: "author", width: "45%", align: "left" },
      { Header: "Agent", accessor: "videoKYC", align: "center" },
      { Header: "Occupation", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Loan", accessor: "loan", align: "center" },
      { Header: "start date", accessor: "employed", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        author: (
          <Author image={team2} name="Divya Sharma" email="divya@gmail.com"  />
        ),
        function: <Job title="Engineer" description="XYZ" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BBDEW8735N
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876543216
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            1234268863683
          </MDTypography>
        ),
        videoKYC: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Done by Suresh
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹70,000
          </MDTypography>
        ),
        action: (
          <>
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              Edit
            </MDTypography>
          </>
        ),
      },
      {
        author: (
          <Author image={team3} name="Shanice Gupta" email="gupta@gmail.com" />
        ),
        function: <Job title="Manager" description="Bsev" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="pending" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            11/01/19
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BHUBC8726Z
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876543343
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            1742167862693
          </MDTypography>
        ),
        videoKYC: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Assigned to Ramesh
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹90,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: (
          <Author image={team4} name="Lokesh Perrier" email="lokesh@gmail.com" />
        ),
        function: <Job title="Executive" description="Projects" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            19/09/17
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BWSBU8735J
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876545417
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            125428862694
          </MDTypography>
        ),
        videoKYC: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Done by Ramesh
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹1,00,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: (
          <Author image={team3} name="Barkha Shokeen" email="barkha@gmail.com"  />
        ),
        function: <Job title="service" description="GHP" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            24/12/08
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            IEBAM8729X
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876541579
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            735267862915
          </MDTypography>
        ),
        videoKYC: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Done by Suresh
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹80,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: (
          <Author image={team3} name="Richard Gran" email="richard@gmail.com" />
        ),
        function: <Job title="Manager" description="Executive" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="pending" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            04/10/21
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            HDBEJ8735S
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876547532
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            123534544351
          </MDTypography>
        ),
        videoKYC: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Assigned to Suresh
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹40,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: (
          <Author image={team4} name="Miriam Eric" email="miriam@gmail.com" />
        ),
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="pending" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            14/09/20
          </MDTypography>
        ),
        pan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            BWEPG8735N
          </MDTypography>
        ),
        phone:(
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          9876548532
        </MDTypography>
        ),
        aadhaar: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            123567864353
          </MDTypography>
        ),
        videoKYC: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Assigned to Dinesh
          </MDTypography>
        ),
        loan: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            ₹50,000
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
    ],
  };
}
