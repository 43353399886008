// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { NavLink } from "react-router-dom";

function Footer({ company, links }) {
  const { href } = company;
  const { size } = typography;

  const renderLinks = () => (
    // links.map((link) => {
    //   return link.name === "Terms And Conditions" ? (
    //     <MDBox key={link.name} component="li" px={2} lineHeight={1}>
    //       <NavLink to={link.href}>
    //         <MDTypography variant="button" fontWeight="regular" color="text">
    //           {link.name}
    //         </MDTypography>
    //       </NavLink>
    //     </MDBox>
    //   ) : link.name === "Sarvvid" ? (
    //     <a href="www.sarvvid-ai.com" target="_blank" rel="noopener noreferrer">
    //       <MDBox key={link.name} component="li" px={2} lineHeight={1}>
    //         <MDTypography variant="button" fontWeight="regular" color="text">
    //           {link.name}
    //         </MDTypography>
    //       </MDBox>
    //     </a>
    //   ) : (
    //     <MDBox key={link.name} component="li" px={2} lineHeight={1}>
    //       <NavLink to={link.href}>
    //         <MDTypography variant="button" fontWeight="regular" color="text">
    //           {link.name}
    //         </MDTypography>
    //       </NavLink>
    //     </MDBox>
    //   );
    // });
    <>
      <MDBox component="li" px={2} lineHeight={1}>
        <a href="www.sarvvid-ai.com">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Sarvvid
          </MDTypography>
        </a>
      </MDBox>
      <MDBox component="li" px={2} lineHeight={1}>
        <NavLink to="/privacypolicy">
          <MDTypography variant="button" fontWeight="regular" color="text">
            PrivacyPolicy
          </MDTypography>
        </NavLink>
      </MDBox>
      <MDBox component="li" px={2} lineHeight={1}>
        <NavLink to="/termsandconditions">
          <MDTypography variant="button" fontWeight="regular" color="text">
            Terms And Conditions
          </MDTypography>
        </NavLink>
      </MDBox>
    </>
  );

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      mt={8}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, made with
        <MDBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
          <Icon color="inherit" fontSize="inherit">
            favorite
          </Icon>
        </MDBox>
        by
        <Link href={href} target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;Sarvvid&nbsp;
          </MDTypography>
        </Link>
        for a better web.
      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "www.sarvvid-ai.com", name: "Sarvvid " },
  links: [
    { href: "www.sarvvid-ai.com", name: "Sarvvid" },
    { href: "/privacypolicy", name: "Privacy Policy" },
    {
      href: "/termsandconditions",
      name: "Terms And Conditions",
    },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
