/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Agent from "layouts/agent";
import AgentForm from "layouts/agentForm";

// @mui icons
import Icon from "@mui/material/Icon";
import Deploy from "layouts/deploy";
import Seeyousoon from "layouts/seeyousoon";
import TermsAndConditions from "layouts/terms&Conditions";

const routes = [
  {
    type: "collapse",
    name: "Instances",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/instances",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Storage-Block/Object",
    key: "storage",
    icon: <Icon fontSize="small">lock</Icon>,
    route: "/storage",
    component: <Seeyousoon />,
  },
  {
    type: "collapse",
    name: "Databases",
    key: "databases",
    icon: <Icon fontSize="small">track_changes</Icon>,
    route: "/databases",
    component: <Seeyousoon />,
  },
  {
    type: "collapse",
    name: "Network",
    key: "network",
    icon: <Icon fontSize="small">sensors</Icon>,
    route: "/network",
    component: <Seeyousoon />,
  },
  {
    type: "collapse",
    name: "Load Balancers",
    key: "loadbalancers",
    icon: <Icon fontSize="small">compare_arrows</Icon>,
    route: "/loadbalancers",
    component: <Seeyousoon />,
  },
  {
    type: "collapse",
    name: "ISOs/Snapshots/Scripts",
    key: "isos",
    icon: <Icon fontSize="small">view_headline</Icon>,
    route: "/isos",
    component: <Seeyousoon />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    // type: "collapse",
    // name: "Agent",
    // key: "agent",
    // icon: <Icon fontSize="small">person</Icon>,
    route: "/agent",
    component: <Agent />,
  },

  {
    // type: "collapse",
    // name: "Agent",
    // key: "agent",
    // icon: <Icon fontSize="small">person</Icon>,
    route: "/agent-form",
    component: <AgentForm />,
  },
];

export default routes;
