// @mui material components
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import Projects from "./components/Projects";
// import CurrencyRupeeSharpIcon from '@mui/icons-material/CurrencyRupeeSharp';

import cloudComputing from "../../illustrations/cloudComputing.json";
import cloudGpu from "../../illustrations/gpuCloud.json";
import MDTypography from "components/MDTypography";
import { operatingSystem, serverLocation, serverSize, server } from "./data";
import Transaction from "layouts/billing/components/Transaction";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import { Box, TextField, ButtonBase } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import authService from "services/authService";
import { useNavigate } from "react-router-dom";

function Deploy() {
  const [serverCost, setServerCost] = useState(492);
  const [usdToInrRate, setUsdToInrRate] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [activeServer, setActiveServer] = useState(1);
  const [activeCpu, setActiveCpu] = useState(1);
  const [activeLocation, setActiveLocation] = useState(1);
  const [activeOs, setActiveOs] = useState(1);
  const [activeServerSize, setActiveServerSize] = useState(1);
  const [hostName, setHostName] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [selectedOS, setSelectedOS] = useState(null);

  const navigate = useNavigate();

  const [serverInfo, setServerInfo] = useState({
    activeServer: server[1], //setting the cloud compute server as default for safe side
    activeLocation: serverLocation[0],
    activeOs: operatingSystem[0],
    activeServerSize: serverSize[0],
    activeVersion: selectedVersion,
    qty: 1,
    hostName,
  });
  const handleSetSelectedVersion = (version) => {
    setSelectedVersion(version);

    setServerInfo((prevInfo) => ({
      ...prevInfo,
      activeVersion: version,
    }));
  };
  const handleInputChange = (event) => {
    console.log("we hit the value");
    const newHostName = event.target.value;
    setHostName(newHostName);
    setServerInfo((prevState) => ({
      ...prevState,
      hostName: newHostName,
    }));
    console.log("serverinfo", serverInfo);
  };
  const handlePayment = async () => {
    console.log("ifno", serverInfo);
    if (serverInfo.hostName === "") {
      console.log("you cant proceed further");
      swal(`Please Enter HostName before proceeding further.`, {
        icon: "error",
      });
    } else if (serverInfo.activeVersion === null) {
      console.log("you cant proceed further");
      swal(
        `Please Select a Operating System Version before proceeding further.`,
        {
          icon: "error",
        }
      );
    } else {
      console.log("payment amount", quantity * serverCost);
      console.log("serverinfo11", serverInfo);
      try {
        function dynamicAmount(serverInfo) {
          if (serverInfo.activeOs.id === 1) {
            return 2340 * 100;
          } else {
            return serverCost * 100;
          }
        }
        const amount = dynamicAmount(serverInfo);
        // if (usdToInrRate) {
        //turn this on as well before actual code processing and uncomment it
        var options = {
          key: process.env.REACT_APP_RAZORPAY_ID,
          key_secret: process.env.REACT_APP_RAZORPAY_SECRET,
          amount: parseInt(amount),

          // amount: parseInt(1 * 100),

          currency: "INR",
          name: `Server: ${hostName}`,
          description: `server: ${hostName}`,
          handler: async function (response) {
            console.log(
              "amount in handler function after payment",
              options.amount
            );
            console.log("we hit handleserver deploy");
            console.log("serverinfo", serverInfo);
            console.log("authservicegetauthtoken", authService.getJwt());
            try {
              const response1 = await axios.post(
                `${process.env.REACT_APP_API_URL}/createServer`,
                {
                  serverInfo,
                  paymentAmount: options.amount,
                  paymentRefNo: response.razorpay_payment_id,
                },
                { headers: { "x-auth-token": authService.getJwt() } }
              );
              console.log("response from create server", response1);
            } catch (err) {
              console.log("there is some error in creating instance", err);
            }
            swal(
              `Payment against the server is made. PaymentId is - ${response.razorpay_payment_id}`,
              {
                icon: "success",
              }
            ).then(() => {
              navigate("/instances");
            });
          },
          // prefill: {
          //   name: "Velmurugan",
          //   email: "mvel1620r@gmail.com",
          //   contact: "7904425033",
          // },
          // notes: {
          //   address: "Razorpay Corporate office",
          // },
          theme: {
            color: "#3399cc",
          },
        };

        const value = await swal(
          "We are offering 7 days free trial for new 1000 users, you can continue with a demo server with all the capabilities and decide to take a plan afterwards.",
          {
            buttons: {
              catch: {
                text: "Start Demo!",
                value: "demo",
              },
              catch2: {
                text: "Take Plan!",
                value: "plan",
              },
            },
          }
        );

        console.log("value", value);
        // Check the value returned by the user's selection
        if (value === "demo") {
          console.log("authservicegetauthtoken", authService.getJwt());
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/createDemoServer`,
              { serverInfo },
              { headers: { "x-auth-token": authService.getJwt() } }
            );
            console.log("response from create server", response);
            swal(`Demo server is started for next 7 days`, {
              icon: "success",
            }).then(() => {
              navigate("/instances");
            });
          } catch (err) {
            swal(`Servers Busy Try Again Later error is-${err}`, {
              icon: "error",
            });
            console.log("there is some error in creating instance", err);
          }
          console.log("serverinfo after clicking demo button", serverInfo);

          console.log("Oh noez! button clicked. Do something...");
        } else if (value === "plan") {
          try {
            var pay = new window.Razorpay(options);
            pay.open();
            // User clicked the "Aww yiss!" button
            // Perform tasks accordingly
            console.log("Aww yiss! button clicked. Do something else...");
          } catch (err) {
            swal(`Servers Busy Try Again Later error is-${err}`, {
              icon: "error",
            });
          }
        }

        // } //uncomment this also
      } catch (error) {
        console.log("Error fetching exchange rate:", error);
        swal(`Servers Busy Try Again Later error is-${error}`, {
          icon: "error",
        });
      }
    }
  };
  const handleServerDeploy = async () => {
    console.log("we hit handleserver deploy");
    console.log("serverinfo", serverInfo);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/createServer`,
      { serverInfo },
      { headers: { "x-auth-token": authService.getJwt() } }
    );
    console.log("response from create server", response);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={4.5}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              mb={3}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Choose Server
              </MDTypography>
            </MDBox>

            <Grid container spacing={3}>
              {server.map((data, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  key={data.id}
                  onClick={
                    data.title === "Cloud Compute"
                      ? () => {
                          setActiveServer(data.id);
                          setServerInfo((prevState) => ({
                            ...prevState,
                            activeServer: data,
                          }));
                          console.log("srverinfo", serverInfo);
                        }
                      : () => console.log("srverinfo", serverInfo)
                  }
                >
                  <MDBox mb={3} className="py-4 px-2">
                    <ButtonBase
                      disableRipple
                      disabled={data.disabled}
                      // style={{ opacity: data. }}
                      sx={{
                        width: "100%",
                        display: "block",
                        transition: "transform .2s",
                        "&:hover": {
                          transform: "scale(1.05)",
                        },
                        opacity: data.disabled ? 0.6 : 1,
                        transform:
                          data.title === serverInfo.activeServer.title
                            ? "scale(1.05)"
                            : "scale(1)",
                      }}
                    >
                      <ReportsBarChart
                        data={data}
                        serverInfo={serverInfo}
                        color="info"
                        title={data.title}
                        description={data.desc}
                        date={data.amount}
                        chart={reportsBarChartData}
                        lottieData={
                          data.title === "Optimized Cloud" ||
                          data.title === "Cloud GPU"
                            ? cloudGpu
                            : cloudComputing
                        }
                      />
                    </ButtonBase>
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </Card>
        </MDBox>
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              mb={3}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Server Location
              </MDTypography>
            </MDBox>
            <Grid container spacing={1}>
              {serverLocation.map((location, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  key={location.id}
                  onClick={
                    location.title === "Delhi" ||
                    location.title === "Bangalore" ||
                    location.title === "Mumbai"
                      ? () => {
                          setActiveLocation(location.id);
                          setServerInfo((prevState) => ({
                            ...prevState,
                            activeLocation: location,
                          }));
                          console.log("serverlocation info", serverInfo);
                        }
                      : () => console.log("serverlocation info", serverInfo)
                  }
                >
                  <MDBox className="p-4">
                    <ButtonBase
                      disableRipple
                      disabled={location.disabled}
                      sx={{
                        width: "100%",
                        display: "block",
                        transition: "transform .2s",
                        "&:hover": {
                          transform: "scale(1.05)",
                        },
                        opacity: location.disabled ? 0.6 : 1,
                        transform:
                          location.title === serverInfo.activeLocation.title
                            ? "scale(1.05)"
                            : "scale(1)",
                      }}
                    >
                      <ComplexStatisticsCard
                        location={location}
                        serverInfo={serverInfo}
                        color={location.color}
                        icon={location.icon}
                        title={location.title}
                        count={location.count}
                      />
                    </ButtonBase>
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </Card>
        </MDBox>
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              mb={3}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Operating System
              </MDTypography>
            </MDBox>
            <Grid container spacing={1}>
              {operatingSystem.map((os) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  key={os.id}
                  onClick={
                    os.type === "Windows Core" || os.type === "Ubuntu"
                      ? () => {
                          setActiveOs(os.id);
                          setServerInfo((prevState) => ({
                            ...prevState,
                            activeOs: os,
                          }));
                          setSelectedOS(selectedOS === os.id ? null : os.id);
                          console.log("serverlocation info", serverInfo);
                        }
                      : () => console.log("serverlocation info", serverInfo)
                  }
                >
                  <MDBox className="p-4">
                    <ButtonBase
                      disableRipple
                      disabled={os.disabled}
                      sx={{
                        width: "100%",
                        display: "block",
                        transition: "transform .2s",
                        "&:hover": {
                          transform: "scale(1.05)",
                        },
                        opacity: os.disabled ? 0.6 : 1,
                        transform:
                          os.type === serverInfo.activeOs.type
                            ? "scale(1.05)"
                            : "scale(1)",
                      }}
                    >
                      <ComplexStatisticsCard
                        os={os}
                        serverInfo={serverInfo}
                        color={os.color}
                        icon={os.icon}
                        title={os.title}
                        count={os.type}
                        version={os.version}
                        selectedVersion={serverInfo.activeVersion}
                        selectedOS={selectedOS}
                        setSelectedVersion={handleSetSelectedVersion}
                      />
                    </ButtonBase>
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </Card>
        </MDBox>
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              mb={3}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Server Size
              </MDTypography>
            </MDBox>
            <Grid container spacing={3}>
              {serverSize.map((serverSize, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  key={serverSize.id}
                  onClick={
                    serverSize.name === "25 GB NVMe"
                      ? () => {
                          setActiveServerSize(serverSize.id);
                          setServerInfo((prevState) => ({
                            ...prevState,
                            activeServerSize: serverSize,
                          }));
                          console.log("serverlocation info", serverInfo);
                        }
                      : () => console.log("serverlocation info", serverInfo)
                  }
                >
                  <MDBox mb={3} className="p-4">
                    <Card>
                      <ButtonBase
                        disableRipple
                        disabled={serverSize.disabled}
                        sx={{
                          width: "100%",
                          display: "block",
                          transition: "transform .2s",
                          "&:hover": {
                            transform: "scale(1.05)",
                          },
                          opacity: serverSize.disabled ? 0.6 : 1,
                          transform:
                            serverSize.name === serverInfo.activeServerSize.name
                              ? "scale(1.05)"
                              : "scale(1)",
                        }}
                      >
                        <DefaultInfoCard
                          serverSize={serverSize}
                          serverInfo={serverInfo}
                          color={serverSize.color}
                          icon={serverSize.icon}
                          title={serverSize.name}
                          description1={serverSize.description1}
                          description2={serverSize.description2}
                          description3={serverSize.description3}
                          value={serverSize.value}
                        />
                      </ButtonBase>
                    </Card>
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </Card>
        </MDBox>
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              mb={3}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Server Host Name
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={3}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <Box
                  component="form"
                  // onSubmit={handleSubmit}

                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    value={hostName}
                    onChange={handleInputChange}
                    margin="normal"
                    // required
                    fullWidth
                    // sx={{ width: "50%" }}
                    id="hostname"
                    label="Host Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                  />
                </Box>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
        <Card>
          <PaymentMethod
            serverInfo={serverInfo}
            quantity={quantity}
            serverCost={serverCost}
            handlePayment={handlePayment}
            handleServerDeploy={handleServerDeploy}
          />
        </Card>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Deploy;
