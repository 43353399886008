// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DefaultInfoCard({
  color,
  icon,
  title,
  description1,
  description2,
  description3,
  value,
  serverSize,
  serverInfo,
}) {
  function activeServerRender(serverInfo, serverSize) {
    if (
      serverSize.name === "25 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "50GB NVMe";
    } else if (
      serverSize.name === "50 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "60GB NVMe";
    } else if (
      serverSize.name === "60 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "100GB NVMe";
    } else if (
      serverSize.name === "100 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "180GB NVMe";
    } else if (serverInfo.activeOs.type === "Ubuntu") {
      return serverSize.name;
    }
  }
  function activeDescription1Render(serverInfo, serverSize) {
    if (
      serverSize.name === "25 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "1 vCPU";
    } else if (
      serverSize.name === "50 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "2 vCPU";
    } else if (
      serverSize.name === "60 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "2 vCPU";
    } else if (
      serverSize.name === "100 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "4 vCPU";
    } else if (serverInfo.activeOs.type === "Ubuntu") {
      return serverSize.description1;
    }
  }
  function activeDescription2Render(serverInfo, serverSize) {
    if (
      serverSize.name === "25 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "2 Gb Memory";
    } else if (
      serverSize.name === "50 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "2 Gb Memory";
    } else if (
      serverSize.name === "60 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "4 Gb Memory";
    } else if (
      serverSize.name === "100 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "8 Gb Memory";
    } else if (serverInfo.activeOs.type === "Ubuntu") {
      return serverSize.description2;
    }
  }
  function activeDescription3Render(serverInfo, serverSize) {
    if (
      serverSize.name === "25 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "3 Tb Bandwidth";
    } else if (
      serverSize.name === "50 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "4 Tb Bandwidth";
    } else if (
      serverSize.name === "60 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "5 Tb Bandwidth";
    } else if (
      serverSize.name === "100 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "6 Tb Bandwidth";
    } else if (serverInfo.activeOs.type === "Ubuntu") {
      return serverSize.description3;
    }
  }
  function activeValueRender(serverInfo, serverSize) {
    if (
      serverSize.name === "25 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "₹2140/month";
    } else if (
      serverSize.name === "50 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "₹3818/month";
    } else if (
      serverSize.name === "60 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "₹4316/month";
    } else if (
      serverSize.name === "100 GB NVMe" &&
      serverInfo.activeOs.type === "Windows Core"
    ) {
      return "₹8632/month";
    } else if (serverInfo.activeOs.type === "Ubuntu") {
      return serverSize.value;
    }
  }
  return (
    <Card
      sx={{
        backgroundImage:
          serverSize?.name === serverInfo?.activeServerSize.name
            ? "linear-gradient(195deg, rgb(163, 210, 251), rgb(119, 178, 255))"
            : null,
      }}
    >
      <MDBox p={2} mx={3} display="flex" justifyContent="center">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="default">{icon}</Icon>
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {activeServerRender(serverInfo, serverSize)}
        </MDTypography>
        {description1 && (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {activeDescription1Render(serverInfo, serverSize)}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {activeDescription2Render(serverInfo, serverSize)}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {activeDescription3Render(serverInfo, serverSize)}
            </MDTypography>
          </MDBox>
        )}
        {description1 && !value ? null : <Divider />}
        {value && (
          <MDTypography variant="h5" fontWeight="medium">
            {activeValueRender(serverInfo, serverSize)}
          </MDTypography>
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DefaultInfoCard;
