// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Lottie from "lottie-react";
import seeYouSoon from "../../illustrations/seeyousoon.json";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// import { useState } from "react";
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import authorsTableLess from "layouts/tables/data/authorTableLess";

function Seeyousoon() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = authorsTableLess();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                pt={3}
              >
                <h1 style={{ marginTop: "3rem" }}>See You Soon</h1>
                <h5 style={{ marginTop: "1rem" }} className="p-4 text-center">
                  This feature will be enabled soon. However you can still use
                  our "instances" feature and create a superfast server.
                </h5>
                <div className="d-flex justify-content-center">
                  <div
                    style={{
                      width: "30rem",
                    }}
                  >
                    <Lottie animationData={seeYouSoon} size={2} />
                  </div>
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Seeyousoon;
