import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const TermsAndConditions = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card className="p-3">
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Terms And Conditions
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    1. Introduction
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    Welcome to Sarvvid, a service provided by Kashiraja Vision
                    Private Limited ("Company", "we", "our", "us"). Our main
                    website is sarvvid-ai.com and our cloud servers website is
                    cloud.sarvvid-ai.com. These Terms and Conditions govern your
                    use of our websites and services, and by using our websites,
                    you agree to these Terms and Conditions in full.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    2. Acceptance of Terms
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    By using our websites, you confirm that you accept these
                    Terms and Conditions and that you agree to comply with them.
                    If you do not agree to these Terms, you must not use our
                    websites. We recommend that you print a copy of these Terms
                    for future reference.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    3. Changes to Terms
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We amend these Terms from time to time. Every time you wish
                    to use our websites, please check these Terms to ensure you
                    understand the terms that apply at that time. Your continued
                    use of our websites following the posting of revised Terms
                    means that you accept and agree to the changes.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    4. Account Responsibilities
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    If you choose, or are provided with, a user identification
                    code, password or any other piece of information as part of
                    our security procedures, you must treat such information as
                    confidential. You must not disclose it to any third party.
                    We have the right to disable any user identification code or
                    password, whether chosen by you or allocated by us, at any
                    time, if in our reasonable opinion you have failed to comply
                    with any of the provisions of these Terms.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    5. Intellectual Property Rights
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We are the owner or the licensee of all intellectual
                    property rights in our websites, and in the material
                    published on it. Those works are protected by copyright laws
                    and treaties around the world. All such rights are reserved.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    6. User Conduct
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    You may use our websites only for lawful purposes. You may
                    not use our websites in any way that breaches any applicable
                    local, national or international law or regulation, or in
                    any way that is unlawful or fraudulent, or has any unlawful
                    or fraudulent purpose or effect.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    7. Termination of Use
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We may terminate or suspend your access to our websites
                    immediately, without prior notice or liability, under our
                    sole discretion, for any reason whatsoever and without
                    limitation, including but not limited to a breach of the
                    Terms.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    8. Disclaimers and Limitations of Liability
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    The information presented on or through our websites is made
                    available solely for general information purposes. We do not
                    warrant the accuracy, completeness or usefulness of this
                    information. Any reliance you place on such information is
                    strictly at your own risk.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    9. Governing Law
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    These Terms and Conditions, their subject matter and their
                    formation, are governed by Indian law. You and we both agree
                    that the courts of India will have exclusive jurisdiction.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    10. Contact Information
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    If you have any questions about these Terms, please contact
                    us at contact@sarvvid-ai.com.
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default TermsAndConditions;
