import * as React from "react";
import { useRef } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
import swal from "sweetalert";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import authService from "../../services/authService";
import sarvvidIcon from "../../imgs/Sarvvid-logo.png";
import sarvvidBackground from "../../imgs/Sarvvid-BG.png";
import httpService from "../../services/httpService";
import axios from "axios";
import Swal from "sweetalert2";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FooterSignIn from "layouts/footer-signin";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {/* {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."} */}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const [email, setEmail] = React.useState(null)
  const [isOtpError, setIsOtpError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleHover = (e) => {
    e.currentTarget.style.transform = "translateY(-5px)";
  };

  const handleHoverExit = (e) => {
    e.currentTarget.style.transform = "translateY(0)";
  };
  const handleForgotPassword = () => {
    console.log("we are in handleforgot password");
    Swal.fire({
      title: "Enter your Email.",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Verify",
      showLoaderOnConfirm: true,
      preConfirm: async (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
          Swal.showValidationMessage("Invalid email format");
          return false; // Stops the modal from closing.
        }

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/forgotpassword`,
            {
              email,
            }
          );
          if (response) {
            if (response.data === "OTP Sent To Email. Please Verify it.") {
              Swal.fire({
                title: "Submit the OTP you got on your registered Email.",
                input: "text",
                inputAttributes: {
                  autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonText: "Verify",
                showLoaderOnConfirm: true,
                preConfirm: async (otp) => {
                  try {
                    const response = await axios.post(
                      `${process.env.REACT_APP_API_URL}/passwordChangeOtpVerification`,
                      {
                        otp,
                        email,
                      }
                    );
                    console.log(
                      "we are getting the following response from otpverification",
                      response
                    );
                    if (response) {
                      console.log("we are here in response of otpverification");
                      if (
                        response.data ===
                        "OTP verified now you can reset your password"
                      ) {
                        (async () => {
                          const { value: newPassword } = await Swal.fire({
                            title: "Reset Password",
                            input: "password",
                            inputAttributes: {
                              autocapitalize: "off",
                              placeholder: "Enter new password",
                            },
                            confirmButtonText: "Next &rarr;",
                            showCancelButton: true,
                            focusConfirm: false,
                            preConfirm: () => {
                              // Get the first password input value
                              const input = Swal.getInput();
                              return input.value;
                            },
                          });

                          if (newPassword) {
                            const { value: confirmPassword } = await Swal.fire({
                              title: "Confirm new password",
                              input: "password",
                              inputAttributes: {
                                autocapitalize: "off",
                                placeholder: "Confirm new password",
                              },
                              confirmButtonText: "Reset",
                              showCancelButton: true,
                              focusConfirm: false,
                              preConfirm: () => {
                                // Get the second password input value
                                const input = Swal.getInput();
                                return input.value;
                              },
                            });

                            // Check if both passwords are not empty and match
                            if (
                              confirmPassword &&
                              newPassword === confirmPassword
                            ) {
                              try {
                                const response = await axios.post(
                                  `${process.env.REACT_APP_API_URL}/resetPasswordMongo`,
                                  { email, newPassword }
                                );

                                console.log(
                                  "response from reset password in mongo",
                                  response
                                );
                                if (response.status === 200) {
                                  swal(`Password Changed Successfully!`, {
                                    icon: "success",
                                  });
                                  // window.location = "/";
                                }
                              } catch (error) {
                                Swal.fire({
                                  icon: "error",
                                  title: "Request failed",
                                  text: error.message,
                                });
                              }
                            } else {
                              Swal.fire({
                                icon: "error",
                                title: "Passwords do not match",
                                text: "Please make sure both passwords are the same.",
                              });
                            }
                          }
                        })();
                      }
                    }
                    // return response.json();
                  } catch (error) {
                    if (
                      error.message === "Request failed with status code 401"
                    ) {
                      console.log("we are in required block");
                      Swal.showValidationMessage(
                        `Request failed: Wrong OTP Entered.`
                      );
                    } else {
                      Swal.showValidationMessage(
                        `Request failed: ${error.message}`
                      );
                    }
                  }
                },
                allowOutsideClick: () => !Swal.isLoading(),
              });
            }
          }
          // return response.json();
        } catch (error) {
          if (error.message === "Request failed with status code 401") {
            console.log("we are in required block");
            Swal.showValidationMessage(`Request failed: Wrong OTP Entered.`);
          } else {
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const data = new FormData(event.currentTarget);
      setEmail(data.get("email").toLowerCase()) //it is only so that we can access the email in error block
      console.log({
        email: data.get("email"),
        password: data.get("password"),
      });
      console.log("processfetch", process.env.REACT_APP_IPADDRESS_API_ENDPOINT);
      const ipAddressFetch = await axios.get(
        process.env.REACT_APP_IPADDRESS_API_ENDPOINT //commented because its not gonna open in localhost. but please uncomment it in production
      );

      const ipAddressData = ipAddressFetch.data;
      const startIndex = ipAddressData.indexOf("{");

      // Find the ending index of the JSON data
      const endIndex = ipAddressData.lastIndexOf("}");

      // Extract the JSON data from the string
      const jsonData = ipAddressData.slice(startIndex, endIndex + 1);

      // Parse the JSON data
      const parsedData = JSON.parse(jsonData);
      // console.log("checking the parsed data", parsedData);
      console.log("checking the IPv4 that came as a response", parsedData.IPv4);
      const responseLogin = await authService.login({
        email: data.get("email").toLowerCase(),
        password: data.get("password"),
        currentIp: parsedData.IPv4,
      });
      console.log("response login", responseLogin);
      if (responseLogin) {
        if (responseLogin.data === "cant fetch IP address for verification") {
          return Swal.fire({
            icon: "error",
            title: "Server Error. Try again later",
            text: "",
          });
        }

        console.log("we are getting response login");
        //
        //
        Swal.fire({
          title: "Submit the OTP you got on your registered Email.",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Verify",
          showLoaderOnConfirm: true,
          preConfirm: async (otp) => {
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/verifyotp`,
                {
                  otp,
                  email: data.get("email"),
                }
              );
              if (response) {
                console.log("response", response);
                localStorage.setItem("token", response.data);
                window.location = "/";
              }
              // return response.json();
            } catch (error) {
              if (error.message === "Request failed with status code 401") {
                console.log("we are in required block");
                Swal.showValidationMessage(
                  `${error.response.data}`
                );
              } else if (error.message === "Request failed with status code 400") {
                Swal.showValidationMessage(
                  `${error.response.data}`
                );
              } else {
                Swal.showValidationMessage(`Request failed: ${error.message}`);
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
        //
        //
      } else {
        swal(`Login Successful!`, {
          icon: "success",
        });
        navigate("/");
      }
    } catch (err) {
      if (err.message === "Request failed with status code 400") {
        if (err.response.data === "Login after Submitting the otp that we sent on your email.") {
          if (isOtpError === true)
            setIsOtpError(false);
          setIsOtpError(true);
        } else {
          swal(`${err.response.data}`, {
            icon: "warning",
          });
        }
      } else {
        console.log("err", err);
        swal(`Login Failed!`, {
          icon: "warning",
        });
      }
    }
  };
  React.useEffect(() => {
    // This code will run whenever the 'email' state changes
    if (isOtpError) {
      // Your specific code related to the 'email' state in the catch block
      console.log("emailabove", email)
      Swal.fire({
        title: "Login after Submitting the otp that we sent on your email.",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Verify",
        showLoaderOnConfirm: true,
        preConfirm: async (otp) => {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/verifyotp`,
              {
                otp,
                email,
              }
            );
            if (response) {
              console.log("response", response);
              localStorage.setItem("token", response.data);
              window.location = "/";
            }
            // return response.json();
          } catch (error) {
            if (error.message === "Request failed with status code 401") {
              console.log("we are in required block");
              Swal.showValidationMessage(
                `${error.response.data}`
              );
            } else if (error.message === "Request failed with status code 400") {
              Swal.showValidationMessage(
                `${error.response.data}`
              );
            } else {
              Swal.showValidationMessage(`Request failed: ${error.message}`);
            }
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
      // ... your code ...
    }
  }, [isOtpError]);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          //   bgcolor: "black",
          bgcolor: "black",
          // padding: "2rem",
          minHeight: "100vh",
          backgroundImage: `url(${sarvvidBackground})`, // Replace "path/to/your/image.jpg" with the actual path to your background image.
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          component="main"
          maxWidth="xs"
        >
          <CssBaseline />
          <Box sx={{ textAlign: "center" }}>
            <img src={sarvvidIcon} style={{ width: "14rem" }} />
          </Box>
          <Box
            sx={{
              marginTop: "5%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "60vh",
              bgcolor: "white",
              padding: "2rem",
              borderRadius: "10px",
              transition: "transform 0.2s ease",
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 7px",
              width: "22.5rem",
            }}
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverExit}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              {/* <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              /> */}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleTogglePasswordVisibility}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <NavLink
                    onClick={handleForgotPassword}
                    to="/"
                    variant="body2"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Forgot password?
                  </NavLink>
                </Grid>
                <Grid item>
                  <NavLink
                    to="/signup"
                    variant="body2"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {"Don't have an account? Sign Up"}
                  </NavLink>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Container>
      </Box>
      {/* <FooterSignIn /> */}
    </ThemeProvider>
  );
}
