// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import MDButton from "components/MDButton";

function ComplexStatisticsCard({
  color,
  title,
  count,
  percentage,
  icon,
  location,
  serverInfo,
  os,
  version,
  selectedVersion,
  setSelectedVersion,
  selectedOS,
}) {
  return (
    <Card
      sx={{
        backgroundImage: os
          ? os?.id === serverInfo?.activeOs.id
            ? "linear-gradient(195deg, rgb(163, 210, 251), rgb(119, 178, 255))"
            : null
          : location?.title === serverInfo?.activeLocation.title
          ? "linear-gradient(195deg, rgb(163, 210, 251), rgb(119, 178, 255))"
          : null,
      }}
    >
      <MDBox display="flex" justifyContent="space-between" py={1} px={2}>
        <MDBox
          variant="gradient"
          // bgColor={color}
          color={color === "light" ? "dark" : "white"}
          // coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="3rem"
          height="3rem"
          sx={{
            backgroundImage: os
              ? os?.title === serverInfo?.activeOs.title
                ? "linear-gradient(195deg, rgb(163, 210, 251), rgb(119, 178, 255))"
                : null
              : location?.title === serverInfo?.activeLocation.title
              ? "linear-gradient(195deg, rgb(163, 210, 251), rgb(119, 178, 255))"
              : null,
          }}
        >
          <img
            src={icon}
            alt={title}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </MDBox>
        <MDBox textAlign="right" lineHeight={2}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {selectedVersion && selectedVersion.osId === os.id
              ? `${selectedVersion.type}`
              : title}
          </MDTypography>
          <MDTypography variant="h6">{count}</MDTypography>
        </MDBox>
      </MDBox>
      <Collapse in={selectedOS === os?.id}>
        {version && os?.id === serverInfo?.activeOs.id && (
          <>
            <Divider />
            <MDBox pb={2} px={2}>
              {version?.map((versions) => {
                return (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    py={1}
                    px={1}
                    key={versions.id}
                  >
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => setSelectedVersion(versions)}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="light"
                        color="white "
                      >
                        {versions.type}
                      </MDTypography>
                    </MDButton>
                  </MDBox>
                );
              })}
            </MDBox>
          </>
        )}
      </Collapse>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.string.isRequired,
};

export default ComplexStatisticsCard;
