import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const PrivacyPolicy = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card className="p-3">
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Privacy Policy
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    1. Introduction
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    Welcome to Sarvvid, a service provided by Kashiraja Vision
                    Private Limited ("Company", "we", "our", "us"). Our main
                    website is sarvvid-ai.com and our cloud servers website is
                    cloud.sarvvid-ai.com. These Terms and Conditions govern your
                    use of our websites and services, and by using our websites,
                    you agree to these Terms and Conditions in full.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    2. What Information is Collected
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We may collect information about you in a variety of ways.
                    The information we may collect on the websites includes:
                    Personal Data: Personally identifiable information, such as
                    your name, shipping address, email address, and telephone
                    number, and demographic information, such as your age,
                    gender, hometown, and interests, that you voluntarily give
                    to us when you register with the websites or when you choose
                    to participate in various activities related to the
                    websites. Derivative Data: Information our servers
                    automatically collect when you access the websites, such as
                    your IP address, your browser type, your operating system,
                    your access times, and the pages you have viewed directly
                    before and after accessing the websites.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    3. How Information is Collected
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We collect information: Directly from you when you provide
                    it to us. Automatically as you navigate through our
                    websites. Information collected automatically may include
                    usage details, IP addresses, and information collected
                    through cookies, web beacons, and other tracking
                    technologies.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    4. How Information is Used
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We use information that we collect about you or that you
                    provide to us, including any personal information: To
                    present our websites and their contents to you. To provide
                    you with information, products, or services that you request
                    from us. To fulfill any other purpose for which you provide
                    it.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    5. How Information is Shared
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We may disclose aggregated information about our users, and
                    information that does not identify any individual, without
                    restriction. We may disclose personal information that we
                    collect or you provide as described in this privacy policy.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    6. User Rights
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    You may review, change, or terminate your account at any
                    time. You may have additional rights under applicable laws,
                    including the right to access, correct, or delete your
                    personal data.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    7. Security Measures
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We use a variety of security measures, including encryption
                    and authentication tools, to help protect your information.
                    We use secure servers when you place orders. All credit card
                    information you supply is transmitted via Secure Socket
                    Layer (SSL) technology and then encrypted within our
                    databases.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    8. Changes to the Privacy Policy
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page.
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <MDTypography variant="h4" color="black">
                    9. Contact Information
                  </MDTypography>
                  <MDTypography variant="subtitle1" color="black">
                    If you have any questions or comments about this Privacy
                    Policy, you can contact us at contact@sarvvid-ai.com.
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default PrivacyPolicy;
