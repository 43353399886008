// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import DataTable from "../../layouts/dashboard/components/Projects/DataTable/index";
// Data

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import authService from "services/authService";
import { Card } from "reactstrap";
import Data from "./data";
import axios from "axios";
import { useEffect, useState } from "react";
function Overview() {
  const [allPayments, setAllPayments] = useState([]);
  useEffect(() => {
    const fetchAllPayments = async () => {
      console.log("we are in the fetch allpayments block");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/allPayments`,
          { headers: { "x-auth-token": authService.getJwt() } }
        );

        console.log("checking the allpayments response", response.data);
        setAllPayments(response.data);
      } catch (error) {
        console.error("Error fetching allpayments", error);
      }
    };

    fetchAllPayments();
  }, []);

  const user = authService.getCurrentUser();
  const { columns } = Data();
  // const dataRow = [
  //   {
  //     lastPaymentMade: "54673829",
  //     serverid: "fgrt45637euhf",
  //     paymentAmount: "45738",
  //     refNumber: "vgftt647eudhfg",
  //   },
  //   {
  //     lastPaymentMade: "54673829",
  //     serverid: "fgrt45637euhf",
  //     paymentAmount: "45738",
  //     refNumber: "vgftt647eudhfg",
  //   },
  //   {
  //     lastPaymentMade: "54673829",
  //     serverid: "fgrt45637euhf",
  //     paymentAmount: "45738",
  //     refNumber: "vgftt647eudhfg",
  //   },
  //   {
  //     lastPaymentMade: "54673829",
  //     serverid: "fgrt45637euhf",
  //     paymentAmount: "45738",
  //     refNumber: "vgftt647eudhfg",
  //   },
  //   {
  //     lastPaymentMade: "54673829",
  //     serverid: "fgrt45637euhf",
  //     paymentAmount: "45738",
  //     refNumber: "vgftt647eudhfg",
  //   },
  // ];
  const Servername = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );
  // console.log("servers that user purchased", userServers);

  let rows;

  if (allPayments && Array.isArray(allPayments)) {
    rows = allPayments.map((data, index) => {
      return {
        lastPaymentMade: (
          <MDBox display="flex" py={1} alignItems="center" gap="10px">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data.lastPaymentMade}
            </MDTypography>
          </MDBox>
        ),
        serverId: (
          <MDBox display="flex" py={1} alignItems="center" gap="10px">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data.serverId}
            </MDTypography>
          </MDBox>
        ),
        paymentAmount: (
          <MDBox display="flex" py={1} alignItems="center" gap="10px">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data.paymentAmount
                ? `₹${data.paymentAmount / 100}`
                : "Not Found"}
            </MDTypography>
          </MDBox>
        ),
        paymentRefNo: (
          <MDBox display="flex" py={1} alignItems="center" gap="10px">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {data.paymentRefNo ? data.paymentRefNo : "Not Found"}
            </MDTypography>
          </MDBox>
        ),
      };
    });
  } else if (allPayments === "No Servers purchased.") {
    rows = [
      {
        lastPaymentMade: (
          <MDBox display="flex" py={1} alignItems="center" gap="10px">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {"-"}
            </MDTypography>
          </MDBox>
        ),
        serverId: (
          <MDBox display="flex" py={1} alignItems="center" gap="10px">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {"-"}
            </MDTypography>
          </MDBox>
        ),
        paymentAmount: (
          <MDBox display="flex" py={1} alignItems="center" gap="10px">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {"-"}
            </MDTypography>
          </MDBox>
        ),
        paymentRefNo: (
          <MDBox display="flex" py={1} alignItems="center" gap="10px">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {"-"}
            </MDTypography>
          </MDBox>
        ),
      },
    ];
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox
          mt={5}
          mb={3}
          mx={1}
          sx={{
            display: "flex",
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={6}
              xl={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ProfileInfoCard
                title="profile information"
                info={{
                  fullName: `${user.firstName} ${user.lastName}`,
                  email: user.email,
                  location: "India",
                }}
                action={{ route: "" }}
                shadow={false}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Card>
                <MDBox
                  mx={2}
                  mt={3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Purchases
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    clickableRows
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
