import indianFlag from "../../imgs/india.png";
import japanFlag from "../../imgs/japan.png";
import singFlag from "../../imgs/singapore.png";
import usFlag from "../../imgs/united-states.png";
import windowsLogo from "../../imgs/windows.png";
import ubuntuLogo from "../../imgs/ubuntu.png";
import alpineLogo from "../../imgs/alpine.png";
import debianLogo from "../../imgs/debian.png";
import rockyLogo from "../../imgs/rocky_linux.png";
import archLogo from "../../imgs/arch.png";
import almaLogo from "../../imgs/almalinux.png";
import centLogo from "../../imgs/cent.png";
export const server = [
  {
    id: 1,
    icon: <i className="fa fa-chart-line fa-3x text-primary"></i>,
    title: "Optimized Cloud",
    amount: "₹2296/month",
    desc: "Virtual machines for more demanding business apps, e.g. production websites, CI/CD, video transcoding, or larger databases.",
    info: "Dedicated vCPU",
    disabled: true,
  },
  {
    id: 2,
    icon: <i className="fa fa-chart-bar fa-3x text-primary"></i>,
    title: "Cloud Compute",
    amount: "₹205/month",
    desc: "Virtual machines for apps with bursty performance, e.g. low traffic websites, blogs, CMS, dev/test environments, and small databases.",
    info: "Shared vCPU",
    disabled: false,
  },
  {
    id: 3,
    icon: <i className="fa fa-chart-area fa-3x text-primary"></i>,
    title: "Cloud GPU",
    amount: "₹1763/month",
    desc: "Virtual machines with fractional or full NVIDIA GPUs for AI, machine learning, HPC, visual computing and VDI. Also available as Bare Metal.",
    info: "NVIDIA GPU + Dedicated vCPU",
    disabled: true,
  },
  {
    id: 4,
    icon: <i className="fa fa-chart-pie fa-3x text-primary"></i>,
    title: "Bare Metal",
    amount: "₹9840/month",
    desc: "Single tenant bare metal for apps with the most demanding robust performance or hightech security requirements.",
    info: "Physical CPU + Optional GPU",
    disabled: true,
  },
];

export const serverLocation = [
  {
    id: 1,

    color: "light",
    icon: indianFlag,
    title: "Delhi",
    count: "India",
    disabled: false,
  },
  {
    id: 2,
    color: "dark",
    icon: indianFlag,
    title: "Bangalore",
    count: "India",
    disabled: false,
  },
  {
    id: 3,

    color: "dark",
    icon: indianFlag,
    title: "Mumbai",
    count: "India",
    disabled: false,
  },
  {
    id: 4,

    color: "dark",
    icon: japanFlag,
    title: "Osaka",
    count: "Japan",
    disabled: true,
  },
  {
    id: 5,

    color: "dark",
    icon: singFlag,
    title: "Sinagpore",
    count: "Singapore",
    disabled: true,
  },
  {
    id: 6,

    color: "dark",
    icon: usFlag,
    title: "Chicago",
    count: "United States",
    disabled: true,
  },
  {
    id: 7,

    color: "dark",
    icon: usFlag,
    title: "Dallas",
    count: "United States",
    disabled: true,
  },
  {
    id: 8,

    color: "dark",
    icon: japanFlag,
    title: "Tokyo",
    count: "Japan",
    disabled: true,
  },
];
export const operatingSystem = [
  {
    id: 1,
    color: "dark",
    icon: windowsLogo,
    title: "Select Version",
    type: "Windows Core",
    disabled: false,
    version: [
      {
        id: 1,
        type: "2022 x64",
        osId: 1,
      },
      {
        id: 2,
        type: "2019 x64",
        osId: 1,
      },
      {
        id: 3,
        type: "2016 x64",
        osId: 1,
      },
    ],
  },
  {
    id: 2,

    color: "dark",
    icon: ubuntuLogo,
    title: "Select Version",
    type: "Ubuntu",
    disabled: false,
    version: [
      {
        id: 1,
        type: "23.04 x64",
        osId: 2,
      },
      {
        id: 2,
        type: "22.04 LTS x64",
        osId: 2,
      },
      {
        id: 3,
        type: "20.04 LTS x64",
        osId: 2,
      },
    ],
  },
  {
    id: 3,

    color: "dark",
    icon: rockyLogo,
    title: "Select Version",
    type: "Rocky Linux",
    disabled: true,
    version: [
      {
        id: 1,
        type: "2022 x64",
        osId: 3,
      },
      {
        id: 2,
        type: "2019 x64",
        osId: 3,
      },
      {
        id: 3,
        type: "2016 x64",
        osId: 3,
      },
    ],
  },
  {
    id: 4,

    color: "dark",
    icon: debianLogo,
    title: "Select Version",
    type: "Debian",
    disabled: true,
    version: [
      {
        id: 1,
        type: "2022 x64",
        osId: 4,
      },
      {
        id: 2,
        type: "2019 x64",
        osId: 4,
      },
      {
        id: 3,
        type: "2016 x64",
        osId: 4,
      },
    ],
  },
  {
    id: 5,

    color: "dark",
    icon: alpineLogo,
    title: "Select Version",
    type: "Alpine linux",
    disabled: true,
    version: [
      {
        id: 1,
        type: "2022 x64",
        osId: 5,
      },
      {
        id: 2,
        type: "2019 x64",
        osId: 5,
      },
      {
        id: 3,
        type: "2016 x64",
        osId: 5,
      },
    ],
  },
  {
    id: 6,

    color: "dark",
    icon: archLogo,
    title: "Select Version",
    type: "Arch linux",
    disabled: true,
    version: [
      {
        id: 1,
        type: "2022 x64",
        osId: 6,
      },
      {
        id: 2,
        type: "2019 x64",
        osId: 6,
      },
      {
        id: 3,
        type: "2016 x64",
        osId: 6,
      },
    ],
  },
  {
    id: 7,

    color: "dark",
    icon: almaLogo,
    title: "Select Version",
    type: "AlmaLinux",
    disabled: true,
    version: [
      {
        id: 1,
        type: "2022 x64",
        osId: 7,
      },
      {
        id: 2,
        type: "2019 x64",
        osId: 7,
      },
      {
        id: 3,
        type: "2016 x64",
        osId: 7,
      },
    ],
  },
  {
    id: 8,

    color: "dark",
    icon: centLogo,
    title: "Select Version",
    type: "CentOS",
    disabled: true,
    version: [
      {
        id: 1,
        type: "2022 x64",
        osId: 8,
      },
      {
        id: 2,
        type: "2019 x64",
        osId: 8,
      },
      {
        id: 3,
        type: "2016 x64",
        osId: 8,
      },
    ],
  },
];
export const serverSize = [
  {
    id: 1,
    color: "info",
    icon: "article",
    name: "25 GB NVMe",
    description1: "1 vCPU",
    description2: "1 Gb Memory",
    description3: "2 Tb Bandwidth",
    value: "₹492/month",
    disabled: false,
  },
  {
    id: 2,
    color: "success",
    icon: "leaderboard",
    name: "50 GB NVMe",
    description1: "1 vCPU",
    description2: "2 Gb Memory",
    description3: "3 Tb Bandwidth",
    value: "₹984/month",
    disabled: true,
  },
  {
    id: 3,
    color: "warning",
    icon: "leaderboard",
    name: "60 GB NVMe",
    description1: "2 vCPU",
    description2: "2 Gb Memory",
    description3: "4 Tb Bandwidth",
    value: "₹1476/month",
    disabled: true,
  },
  {
    id: 4,
    color: "primary",
    icon: "article",
    name: "100 GB NVMe",
    description1: "2 vCPU",
    description2: "4 Gb Memory",
    description3: "5 Tb Bandwidth",
    value: "₹1968/month",
    disabled: true,
  },
];
